<template>
  <div>
    <expansion-panels-layout :open="openExpansionPanel">
      <!-- header -->
      <template v-slot:header>
        <record-details-banner :record="currentRecord">
          <document-link
            :record="currentRecord"
            v-on:click="showDocumentEditorDialog"
            :use-default-pdf-viewer="true"
            @openDefaultPdfViewer="openDefaultPdfViewerDialog"
          ></document-link>
        </record-details-banner>
      </template>

      <!-- content -->
      <template v-slot:content>
        <v-tabs
          class="mt-2 mb-3"
          show-arrows
          v-if="visibleAgendaTabs && !isEventDocumentAssembly"
          v-model="tab"
        >
          <v-tab
            v-for="tab in agendaTabs"
            :key="tab.type"
            @click="setCurrentTab(tab.type)"
          >
            <v-icon>{{ tab.icon }}</v-icon>
            <div v-if="visibleOnMdAndUp" class="ml-2">
              {{ tab.name }}
            </div>
          </v-tab>
        </v-tabs>
        <v-card flat v-if="visibleAdvanceAgendaTabContent">
          <agenda-tabs-content
            :tab="currentTab"
            :record="currentRecord"
          ></agenda-tabs-content>
        </v-card>
        <v-card flat v-else>
          <!-- Record form -->
          <form-record
            ref="formRecord"
            :record="currentRecord"
            :full-record="record"
            :categories="categories"
            :category="currentRecordCategory"
            :agendaItemTemplates="agendaItemTemplates"
            :agendaSectionTemplates="agendaSectionTemplates"
            :form-mode="currentRecordMode"
            :loadingCategory="loadingCategory"
            :loadingFieldValueId="loadingFieldValueId"
            :loadingDatabaseLookupData="loadingDatabaseLookupData"
            :acquire-new-file="acquireNewFile"
            :local-file="localUploadedFile"
            v-model="isValidFormRecord"
            :is-document-assembly="isDocumentAssembly"
            @projectTemplateChanged="onProjectTemplateChanged"
            @agendaTemplateChanged="onAgendaTemplateChanged"
            @AgendaItemTemplateChanged="onAgendaItemTemplateChanged"
            @categoryChanged="onCategoryChanged"
            @moreText="onMoreText"
            @databaseLookup="onDatabaseLookup"
            @agendaPacketOptions="onAgendaPacketOptionsChanged"
            @uploadFileChanged="onUploadFileChanged"
            @checkInOptionChanged="onCheckInOptionChanged"
          ></form-record>

          <!-- divider of Switch: Start New Record Project -->
          <v-divider v-show="visibleSwitchStartNewRecordProject"></v-divider>

          <!-- switch: Start New Record Project -->
          <v-container class="px-0" fluid>
            <v-switch
              v-show="visibleSwitchStartNewRecordProject"
              v-model="isNewRecordProject"
              dense
              :label="switchStartNewRecordProjectLabel"
              :disabled="!enabledSwitchStartNewRecordProject"
            ></v-switch>
          </v-container>

          <!-- New Workflow Project-->
          <expansion-panels-layout
            class="mt-3"
            v-show="visibleNewWorkflowProject"
            :open="expandNewWorkflowProject"
          >
            <!-- project header -->
            <template v-slot:header>
              <details-banner-layout>
                <template v-slot:avatar>
                  <v-icon :color="projectHeaderIconColorClass">{{
                    iconWFProject
                  }}</v-icon>
                </template>
                <template v-slot:default>
                  {{ commandNewProjectLabel }}
                </template>
                <!-- project header - actions -->
                <template v-slot:actions>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        right
                        v-on="on"
                        :color="projectHeaderIconColorClass"
                        v-show="!isValidNewProject"
                        >{{ iconWarning }}</v-icon
                      >
                    </template>
                    <span>{{ projectValidationMessage }}</span>
                  </v-tooltip>
                </template>
              </details-banner-layout>
            </template>
            <template v-slot:content>
              <form-new-project
                ref="formNewProject"
                v-model="isValidFormNewProject"
                :project="projectNew"
                :template="projectNewTemplate"
                :browse-project-templates="enabledSwitchStartNewRecordProject"
                @projectTemplateChanged="onProjectTemplateChanged"
                @task-validation="onValidateProjectTasks"
              ></form-new-project>
            </template>
          </expansion-panels-layout>

          <!-- Commands actions divider -->
          <v-divider v-show="isAllowedAuthor"></v-divider>

          <!-- Record's Commands -->
          <v-card-actions v-show="visibleCommandOpen">
            <!-- record new -->
            <base-drop-down-menu-button top v-if="visibleCommandNewRecord">
              <template v-slot:iconName>
                {{ iconAdd }}
              </template>
              {{ commandNewLabel }}
              <template v-slot:commands>
                <!-- Agenda Meeting -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.MEETING)"
                  @click="onNewRecord(recordTypes.MEETING)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FOLDER)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconAgendaMeeting }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.MEETING) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.MEETING) }}
                </base-menu-item>

                <!-- divider of Agenda Meeting -->
                <v-divider
                  v-show="visibleNewRecord(recordTypes.MEETING)"
                ></v-divider>

                <!-- Document Template -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.MEETING, true)"
                  @click="onCreateDocumentTemplate"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FOLDER)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconDocumentAssembly }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandDocumentAssembly }}
                    </div>
                  </template>
                  {{ commandDocumentAssembly }}
                </base-menu-item>

                <!-- divider of Document template -->
                <v-divider
                  v-show="visibleNewRecord(recordTypes.MEETING, true)"
                ></v-divider>

                <!-- Folder -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.FOLDER)"
                  @click="onNewRecord(recordTypes.FOLDER)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FOLDER)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconFolder }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.FOLDER) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.FOLDER) }}
                </base-menu-item>

                <!-- Duplicate Folder -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewDuplicateRecord(recordTypes.FOLDER)"
                  @click="onNewDuplicateRecord(recordTypes.FOLDER, true)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FOLDER)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconFolderMultiple }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewDuplicateRecordLabel(recordTypes.FOLDER) }}
                    </div>
                  </template>
                  {{ commandNewDuplicateRecordLabel(recordTypes.FOLDER) }}
                </base-menu-item>

                <!-- divider of New FOLDER/Duplicate FOLDER -->
                <v-divider
                  v-show="
                    visibleNewRecord(recordTypes.FOLDER) ||
                      visibleNewDuplicateRecord(recordTypes.FOLDER)
                  "
                ></v-divider>

                <!-- Compound Document -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.DOCUMENT)"
                  @click="onNewRecord(recordTypes.DOCUMENT)"
                >
                  <template v-slot:iconName>
                    {{ iconDocument }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.DOCUMENT) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.DOCUMENT) }}
                </base-menu-item>

                <!-- Compound Document (Inherit Parent Data) -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewInheritRecord"
                  @click="onNewDuplicateRecord(recordTypes.DOCUMENT, true)"
                >
                  <template v-slot:iconName>
                    {{ iconDocument }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordInheritLabel(recordTypes.DOCUMENT) }}
                    </div>
                  </template>
                  {{ commandNewRecordInheritLabel(recordTypes.DOCUMENT) }}
                </base-menu-item>

                <!-- Duplicate Compound Document -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewDuplicateRecord(recordTypes.DOCUMENT)"
                  @click="onNewDuplicateRecord(recordTypes.DOCUMENT)"
                >
                  <template v-slot:iconName>
                    {{ iconDocument }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewDuplicateRecordLabel(recordTypes.DOCUMENT) }}
                    </div>
                  </template>
                  {{ commandNewDuplicateRecordLabel(recordTypes.DOCUMENT) }}
                </base-menu-item>

                <!-- divider of New DOCUMENT/Duplicate DOCUMENT -->
                <v-divider
                  v-show="
                    visibleNewRecord(recordTypes.DOCUMENT) ||
                      visibleNewDuplicateRecord(recordTypes.DOCUMENT)
                  "
                ></v-divider>

                <!-- Record -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.RECORD)"
                  @click="onNewRecord(recordTypes.RECORD)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.RECORD)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconRecord }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.RECORD) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.RECORD) }}
                </base-menu-item>

                <!-- Duplicate Record -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewDuplicateRecord(recordTypes.RECORD)"
                  @click="onNewDuplicateRecord(recordTypes.RECORD)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.RECORD)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconRecordMultiple }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewDuplicateRecordLabel(recordTypes.RECORD) }}
                    </div>
                  </template>
                  {{ commandNewDuplicateRecordLabel(recordTypes.RECORD) }}
                </base-menu-item>

                <!-- Record (Inherit Parent Data) -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewInheritRecord"
                  @click="onNewDuplicateRecord(recordTypes.RECORD, true)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.RECORD)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconRecord }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordInheritLabel(recordTypes.RECORD) }}
                    </div>
                  </template>
                  {{ commandNewRecordInheritLabel(recordTypes.RECORD) }}
                </base-menu-item>

                <!--
                  Agenda
                -->

                <!-- Agenda Section -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.SECTION)"
                  @click="onNewRecord(recordTypes.SECTION)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.SECTION)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconAgendaSection }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.SECTION) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.SECTION) }}
                </base-menu-item>

                <!-- Agenda Item -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewRecord(recordTypes.ITEM)"
                  @click="onNewRecord(recordTypes.ITEM)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.ITEM)"
                      :disabled="!enableNewRecord"
                    >
                      {{ iconAgendaItem }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewRecordLabel(recordTypes.ITEM) }}
                    </div>
                  </template>
                  {{ commandNewRecordLabel(recordTypes.ITEM) }}
                </base-menu-item>

                <!-- Agenda Document -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewAgendaDocument()"
                  @click="
                    onNewAgendaDocument(agendaDocumentType.agendaDocument)
                  "
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FILE)"
                      :disabled="!enableNewRecord"
                    >
                      {{
                        iconNewAgendaDocument(agendaDocumentType.agendaDocument)
                      }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{
                        commandNewAgendaDocumentLabel(
                          agendaDocumentType.agendaDocument
                        )
                      }}
                    </div>
                  </template>
                  {{
                    commandNewAgendaDocumentLabel(
                      agendaDocumentType.agendaDocument
                    )
                  }}
                </base-menu-item>

                <!-- Agenda Draft Document -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewAgendaDocument(true)"
                  @click="onNewAgendaDocument(agendaDocumentType.agendaDraft)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FILE)"
                      :disabled="!enableNewRecord"
                    >
                      {{
                        iconNewAgendaDocument(agendaDocumentType.agendaDraft)
                      }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{
                        commandNewAgendaDocumentLabel(
                          agendaDocumentType.agendaDraft
                        )
                      }}
                    </div>
                  </template>
                  {{
                    commandNewAgendaDocumentLabel(
                      agendaDocumentType.agendaDraft
                    )
                  }}
                </base-menu-item>

                <!-- Agenda Packet -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewAgendaDocument()"
                  @click="onNewAgendaDocument(agendaDocumentType.agendaPacket)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FILE)"
                      :disabled="!enableNewRecord"
                    >
                      {{
                        iconNewAgendaDocument(agendaDocumentType.agendaPacket)
                      }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{
                        commandNewAgendaDocumentLabel(
                          agendaDocumentType.agendaPacket
                        )
                      }}
                    </div>
                  </template>
                  {{
                    commandNewAgendaDocumentLabel(
                      agendaDocumentType.agendaPacket
                    )
                  }}
                </base-menu-item>

                <!-- Agenda Outline -->
                <base-menu-item
                  :disabled="!enableNewRecord"
                  v-show="visibleNewAgendaDocument(true)"
                  @click="onNewAgendaDocument(agendaDocumentType.agendaOutline)"
                >
                  <template v-slot:icon>
                    <v-icon
                      :color="recordTypeIconColorClass(recordTypes.FILE)"
                      :disabled="!enableNewRecord"
                    >
                      {{
                        iconNewAgendaDocument(agendaDocumentType.agendaOutline)
                      }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{
                        commandNewAgendaDocumentLabel(
                          agendaDocumentType.agendaOutline
                        )
                      }}
                    </div>
                  </template>
                  {{
                    commandNewAgendaDocumentLabel(
                      agendaDocumentType.agendaOutline
                    )
                  }}
                </base-menu-item>

                <!-- divider of New Workflow Project -->
                <v-divider
                  v-show="visibleNewWorkflowProjectCommand"
                ></v-divider>

                <base-menu-item
                  v-show="visibleNewWorkflowProjectCommand"
                  @click="onNewWorkflowProject()"
                >
                  <template v-slot:icon>
                    <v-icon>
                      {{ iconProjects }}
                    </v-icon>
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandNewProjectLabel }}
                    </div>
                  </template>
                  {{ commandNewProjectLabel }}
                </base-menu-item>
              </template>
            </base-drop-down-menu-button>

            <v-divider vertical v-if="visibleCommandEdit"></v-divider>

            <!-- Edit Record (remarks: has to be base-button) -->
            <base-button
              v-show="visibleCommandEdit"
              :reactive-text="true"
              :disabled="!enableCommandEdit"
              @click="onRecordEdit"
            >
              {{ commandEditRecordLabel }}
              <template v-slot:icon-name>
                {{ recordOperationIcon(recordOperation.Update) }}
              </template>
            </base-button>

            <v-divider vertical v-if="visibleCommandAcquireRecord"></v-divider>

            <!-- record acquire -->
            <base-drop-down-menu-button
              top
              ref="refDropDownMenuAcquire"
              v-if="visibleCommandAcquireRecord"
            >
              <!-- Acquire -->
              <template v-slot:iconName>
                {{ iconAcquire }}
              </template>
              {{ commandAcquireLabel }}

              <!-- Acquire commands -->
              <template v-slot:commands>
                <!-- Acquire - Scan -->
                <base-menu-item
                  :disabled="!enabledCommandScan"
                  v-show="visibleCommandScan"
                  @click="onAcquireScan"
                >
                  <template v-slot:iconName>
                    {{ iconScanner }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandAcquireScanLabel }}
                    </div>
                  </template>
                  {{ commandAcquireScanLabel }}
                </base-menu-item>

                <!-- Acquire -->
                <template v-if="visibleEditFileVersion">
                  <!-- Acquire - Edit checked out File Version -->
                  <base-menu-item
                    :disabled="!enabledCommandUploadFile"
                    @click="onEditFileVersion"
                  >
                    <template v-slot:iconName>
                      {{ iconUploadFile }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ uploadFileMenuLabel }}
                      </div>
                    </template>
                    {{ uploadFileMenuLabel }}
                  </base-menu-item>
                </template>

                <!-- Acquire - Upload File (using Popover Menu) -->
                <template v-else>
                  <template v-if="isCompoundRecord">
                    <base-menu-item
                      :disabled="!enabledCommandUploadFile"
                      @click="openUploadCompoundDocumentDialog"
                    >
                      <template v-slot:iconName>
                        {{ iconUploadFile }}
                      </template>
                      <template v-slot:tooltipContent>
                        <div>
                          {{ uploadFileMenuLabel }}
                        </div>
                      </template>
                      {{ uploadFileMenuLabel }}
                    </base-menu-item>
                  </template>

                  <template v-else-if="isAllowedDirectFileUpload">
                    <base-menu-item
                      :disabled="!enabledCommandUploadFile"
                      @click="onNewRecord(recordTypes.FILE)"
                    >
                      <template v-slot:iconName>
                        {{ iconUploadFile }}
                      </template>
                      <template v-slot:tooltipContent>
                        <div>
                          {{ uploadFileMenuLabel }}
                        </div>
                      </template>
                      {{ uploadFileMenuLabel }}
                    </base-menu-item>
                  </template>

                  <template v-else
                    ><import-file-popover-menu
                      v-model="visibleUploadFilePopoverMenu"
                      v-show="isAllowedOperation(recordOperation.Update)"
                      :disabled="!enabledCommandUploadFile"
                      :current-record="currentRecord"
                      :is-document-viewer="false"
                      :import-event="onUploadFile"
                      :progress="uploadFileProgress"
                      @close="visibleUploadFilePopoverMenu = false"
                    >
                    </import-file-popover-menu>
                  </template>
                </template>
              </template>
            </base-drop-down-menu-button>

            <v-divider
              vertical
              v-show="visibleCommandRecordActions"
            ></v-divider>

            <!-- Record Actions -->
            <base-drop-down-menu-button
              top
              ref="refDropDownMenuActions"
              v-if="visibleCommandRecordActions"
            >
              <template v-slot:iconName>
                {{ iconMore }}
              </template>
              <template v-slot:commands>
                <!-- Agenda Meeting Actions -->
                <base-menu-item
                  v-for="action in agendaMeetingActions"
                  :key="action.name"
                  :disabled="!enableAgendaMeetingCommandOperation(action.name)"
                  v-show="visibleAgendaMeetingCommandOperation(action.name)"
                  @click="onAgendaMeetingOperation(action.name)"
                >
                  <template v-slot:iconName>
                    {{ action.icon }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ action.description }}
                    </div>
                  </template>
                  {{ action.label }}
                </base-menu-item>

                <v-divider
                  v-show="isAllowedAuthor && visibleSubmitRecallItemsOperation"
                ></v-divider>

                <!-- Agenda Meeting/Section Submit All Items -->
                <base-menu-item
                  :disabled="
                    !enabledSubmitRecallItemsOperation(
                      agendaItemOperation.submit
                    )
                  "
                  v-show="visibleSubmitRecallItemsOperation"
                  @click="onSubmitAgendaItems"
                >
                  <template v-slot:iconName>
                    {{ iconSubmit }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipSubmitAllItems }}
                    </div>
                  </template>
                  {{ labelSubmitAllItems }}
                </base-menu-item>

                <!-- Agenda Meeting/Section Recall All Items -->
                <base-menu-item
                  :disabled="
                    !enabledSubmitRecallItemsOperation(
                      agendaItemOperation.recall
                    )
                  "
                  v-show="visibleSubmitRecallItemsOperation"
                  @click="onRecallAgendaItems"
                >
                  <template v-slot:iconName>
                    {{ iconRecall }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipRecallAllItems }}
                    </div>
                  </template>
                  {{ labelRecallAllItems }}
                </base-menu-item>

                <!-- Agenda Item Actions -->
                <base-menu-item
                  v-for="action in agendaItemActions"
                  :key="action.name"
                  :disabled="!enableAgendaItemCommandOperation(action.name)"
                  v-show="visibleAgendaItemCommandOperation(action.name)"
                  @click="onAgendaItemOperation(action.name)"
                >
                  <template v-slot:iconName>
                    {{ action.icon }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ action.description }}
                    </div>
                  </template>
                  {{ action.label }}
                </base-menu-item>

                <!-- Commit Refer/Defer -->
                <base-menu-item
                  v-show="isAllowedAuthor && isAllowedCommitReferDefer()"
                  :disabled="!enableCommitReferDefer()"
                  @click="onCommitReferDefer()"
                >
                  <template v-slot:iconName>
                    {{ iconPaste }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>Commit {{ isReferOrDefer() }}</div>
                  </template>
                  Commit {{ isReferOrDefer() }}
                </base-menu-item>

                <v-divider
                  v-show="isAllowedAuthor && visibleAgendaOperationDivider"
                ></v-divider>

                <!-- Record Source Control Actions -->
                <base-menu-item
                  v-for="action in sourceControlActions"
                  :key="action.name"
                  :disabled="!enableRecordCommandOperation(action.name)"
                  v-show="visibleRecordCommandOperation(action.name)"
                  @click="onRecordAction(action.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(action.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>{{ recordOperationTooltip(action.name) }}</div>
                  </template>
                  {{ recordOperationLabel(action.name) }}
                </base-menu-item>

                <!-- Check In File -->
                <template v-if="isCheckedOutForSign">
                  <import-file-popover-menu
                    v-model="visibleCheckInFilePopoverMenu"
                    v-show="
                      visibleRecordCommandOperation(recordOperation.CheckIn)
                    "
                    :disabled="
                      !enableRecordCommandOperation(recordOperation.CheckIn)
                    "
                    :current-record="currentRecord"
                    :is-document-viewer="false"
                    :is-signed-checkin="true"
                    :import-event="onUploadFile"
                    :progress="uploadFileProgress"
                    @close="visibleCheckInFilePopoverMenu = false"
                  >
                  </import-file-popover-menu>
                </template>

                <template v-else>
                  <base-menu-item
                    v-show="
                      visibleRecordCommandOperation(recordOperation.CheckIn)
                    "
                    :disabled="
                      !enableRecordCommandOperation(recordOperation.CheckIn)
                    "
                    @click="openCheckInDialog()"
                  >
                    <template v-slot:iconName>
                      {{ recordOperationIcon(recordOperation.CheckIn) }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ recordOperationTooltip(recordOperation.CheckIn) }}
                      </div>
                    </template>
                    {{ recordOperationLabel(recordOperation.CheckIn) }}
                  </base-menu-item>
                </template>

                <v-divider
                  v-show="isAllowedAuthor && !isEventDocumentAssembly"
                ></v-divider>

                <!-- record Attributes Operations -->
                <base-menu-item
                  v-for="action in recordAttributesOperations"
                  :key="action.name"
                  :disabled="!enableRecordCommandOperation(action.name)"
                  v-show="visibleRecordCommandOperation(action.name)"
                  @click="onRecordAction(action.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(action.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>{{ recordOperationTooltip(action.name) }}</div>
                  </template>
                  {{ recordOperationLabel(action.name) }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- Re-Index -->
                <base-menu-item
                  v-show="
                    visibleRecordCommandOperation(recordOperation.Reindex)
                  "
                  :disabled="
                    !enableRecordCommandOperation(recordOperation.Reindex)
                  "
                  @click="onReindexAction()"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(recordOperation.Reindex) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(recordOperation.Reindex) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(recordOperation.Reindex) }}
                </base-menu-item>

                <!-- record Delete/Purge Operations -->
                <base-menu-item
                  v-for="operation in recordDeleteOperations"
                  :key="operation.name"
                  :disabled="!enableRecordCommandOperation(operation.name)"
                  :is-icon-danger="isRecordOperationDanger(operation.name)"
                  v-show="visibleRecordCommandOperation(operation.name)"
                  @click="onRecordAction(operation.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(operation.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ operation.tooltip }}
                    </div>
                  </template>
                  {{ operation.label }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- record Send Operations -->
                <base-menu-item
                  v-for="operation in recordSendOperations"
                  :key="operation.name"
                  :disabled="!enableRecordCommandOperation(operation.name)"
                  v-show="visibleRecordCommandOperation(operation.name)"
                  @click="onRecordAction(operation.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(operation.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(operation.name) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(operation.name) }}
                </base-menu-item>
              </template>
            </base-drop-down-menu-button>

            <!-- Command Validate record data entry-->
            <base-tooltip-button
              v-if="visibleCommandValidate"
              @click="onValidateForm"
            >
              <template v-slot:icon-name>
                {{ iconValidate }}
              </template>
              <template v-slot:default v-if="visibleOnSmAndUp">
                {{ commandValidateFormLabel }}
              </template>
              <template v-slot:toolTip>
                {{ commandValidateFormToolTip }}
              </template>
            </base-tooltip-button>

            <!-- Create record -->
            <base-tooltip-button
              v-if="visibleCommandCreate"
              :loading="isLoadingCreateRecord"
              :disabled="!enabledCommandCreate"
              @click="onCreate"
            >
              <template v-slot:icon>
                <record-badge-icon
                  v-show="recordNew"
                  :record="recordNew"
                  :badge-bordered="true"
                ></record-badge-icon>
              </template>
              <template v-slot:default>
                {{ commandCreateLabel }}
              </template>
              <template v-slot:toolTip>
                {{ commandCreateRecordToolTip }}
              </template>
            </base-tooltip-button>

            <!-- Save record -->
            <base-tooltip-button
              v-if="visibleCommandSaveRecord"
              :loading="isLoadingSaveRecord"
              :disabled="!isValidFormRecord"
              @click="onSaveRecord"
            >
              <template v-slot:icon-name>
                {{ iconSave }}
              </template>
              <template v-slot:default>
                {{ commandSaveRecordLabel }}
              </template>
              <template v-slot:toolTip>
                {{ commandSaveRecordToolTip }}
              </template>
            </base-tooltip-button>

            <!-- Cancel new/edit record mode-->
            <base-tooltip-button
              v-if="visibleCommandCancelRecordAction"
              @click="onCancelRecordAction"
            >
              <template v-slot:icon-name>
                {{ iconUndo }}
              </template>
              <template v-slot:default>
                {{ commandCancelRecordAction }}
              </template>
              <template v-slot:toolTip>
                {{ commandCancelRecordActionToolTip }}
              </template>
            </base-tooltip-button>
          </v-card-actions>
        </v-card>

        <!-- Delete current record Dialog -->
        <delete-record-dialog
          :record="currentRecord"
          :visible="visibleDialogDelete"
          :perform-action="recordDelete"
          @close="closeDeleteRecordDialog"
        ></delete-record-dialog>

        <!-- Download File Dialog -->
        <download-file-dialog
          :record="currentRecord"
          :visible="visibleDownloadFileDialog"
          :is-email="isEmail"
          @close="closeDownloadFileDialog"
        >
        </download-file-dialog>

        <!-- Print File Dialog -->
        <print-file-dialog
          :record="currentRecord"
          :visible="visiblePrintFileDialog"
          @close="closePrintFileDialog"
        >
        </print-file-dialog>

        <!-- Check In Dialog -->
        <check-in-dialog
          :visible="visibleCheckInDialog"
          :record="currentRecord"
          :page-count="filePageCount"
          :check-in-event="checkInEditedFile"
          @close="visibleCheckInDialog = false"
        >
        </check-in-dialog>

        <!-- Release Dialog -->
        <release-dialog
          :visible="visibleDialogRelease"
          :record="currentRecord"
          :is-file-changed="isFileChanged"
          :release-event="recordRelease"
          @close="visibleDialogRelease = false"
        >
        </release-dialog>

        <!-- Document Editor Dialog -->
        <document-editor-dialog
          :id="editRecordId"
          :version="editRecordVersion"
          :visible="visibleDocumentEditorDialog"
          :record="currentRecord"
          :open-scan-dialog="openScanDialog"
          @update-record="onRecordChanged"
          @close="closeDocumentEditorDialog"
        ></document-editor-dialog>

        <!-- Document Editor Dialog -->
        <default-pdf-viewer-dialog
          :visible="visibleDefaultPdfViewerDialog"
          :record="currentRecord"
          @onCheckout="recordCheckOut(true)"
          @close="closeDefaultPdfViewerDialog"
        ></default-pdf-viewer-dialog>

        <!-- Upload Compound Document Dialog -->
        <upload-compound-document-dialog
          :visible="visibleUploadCompoundDocumentDialog"
          :current-record="currentRecord"
          :progress="uploadFileProgress"
          :upload-document-event="uploadFileToCompoundDocument"
          @close="closeUploadCompoundDocumentDialog"
        ></upload-compound-document-dialog>

        <!-- refer agenda item dialog -->
        <agenda-item-refer-dialog
          :visible="visibleReferDialog"
          :source-record="sourceRecord"
          :destination-record="destinationRecord"
          :agenda-item-templates="agendaItemTemplates"
          :refer-event="onItemRefer"
          @close="visibleReferDialog = false"
        >
        </agenda-item-refer-dialog>

        <!-- defer agenda item dialog -->
        <agenda-item-defer-dialog
          :visible="visibleDeferDialog"
          :source-record="sourceRecord"
          :destination-record="destinationRecord"
          :agenda-item-templates="agendaItemTemplates"
          :defer-event="onItemDefer"
          @close="visibleDeferDialog = false"
        >
        </agenda-item-defer-dialog>

        <!-- re-index dialog -->
        <re-index-dialog
          :visible="visibleReindexDialog"
          :record="currentRecord"
          :reindex-event="onReindex"
          @close="closeReIndexDialog"
        >
        </re-index-dialog>

        <submit-recall-agenda-items-dialog
          :record="currentRecord"
          :visible="visibleSubmitRecallAgendaItemsDialog"
          :is-recall="isRecallOperation"
          :perform-action="onItemsSubmitRecall"
          @close="closeSubmitRecallAgendaItemsDialog"
        ></submit-recall-agenda-items-dialog>
      </template>
    </expansion-panels-layout>

    <!-- display snackbar on demand -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      :timeout="snackbarTimeout"
      :multi-line="snackbarMultiLine"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
// mutation-types
import {
  SET_RECORD_MODE,
  SET_RECORD_NEW,
  SET_RECORD_EDIT,
  SET_UPLOAD_FILE_PROGRESS,
  UPDATE_RECORD_INFO,
  SET_MOVED_RECORD,
  SET_LOCAL_UPLOADED_FILE,
  APPEND_RECORDS
} from "@/store/shared/mutationTypes/record/record-mutation-types";
import { SET_RECORD_NEW_PROJECT } from "@/store/shared/mutationTypes/record/hierarchy-mutation-types";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "folder"
);

// mixins
import { recordDetailActionMixin } from "@/mixins/shared/record/recordDetailActionMixin";

export default {
  name: "FolderRecordDetails",
  mixins: [recordDetailActionMixin],
  computed: {
    ...mapState({
      /**
       * Get current record
       * Overwrite computed recordDetailMixin.record()
       */
      record: "record",

      /**
       * Get current record file version
       * Overwrite computed recordDetailMixin.recordFileVersion()
       */
      recordFileVersion: "recordFileVersion",

      /**
       * Get versions
       */
      versions: "versions",

      /**
       * Current record Mode
       * Overwrite computed recordDetailActionMixin.currentRecordMode()
       */
      currentRecordMode: "recordMode",

      /**
       * Current record New
       * Overwrite computed recordDetailActionMixin.recordNew()
       */
      recordNew: "recordNew",

      /**
       * Current record New Category
       * Overwrite computed recordDetailActionMixin.recordNewCategory()
       */
      recordNewCategory: "recordNewCategory",

      /**
       * Get current record category
       * Overwrite computed recordDetailMixin.recordCategory()
       */
      recordCategory: "recordCategory",

      /**
       * Overwrite Get upload File Progress
       */
      uploadFileProgress: "uploadFileProgress",

      /**
       * Moved record for copy/cut/paste
       */
      movedRecord: "movedRecord",

      /**
       * Local uploaded File
       */
      localUploadedFile: "localUploadedFile",

      /**
       * current new workflow project
       * remarks: Overwrite recordDetailActionMixin.projectNew
       * @type {{recordId: Number, projectName: string, comment: string, templateId: number, runOnlyOneProject: boolean}}
       */
      projectNew: "projectNew",

      /**
       * current new workflow project template
       * remarks: Overwrite recordDetailActionMixin.projectNewTemplate
       */
      projectNewTemplate: "projectNewTemplate"
    })
  },
  methods: {
    ...mapActions({
      /**
       * set Read Only Record
       * Overwrite method recordDetailActionMixin.setReadOnlyRecord(id)
       */
      setReadOnlyRecord: "setReadOnlyRecord",

      /**
       * lock Record
       * Overwrite method recordDetailActionMixin.lockRecord(id)
       */
      lockRecord: "lockRecord",

      /**
       * unLock Record
       * Overwrite method recordDetailActionMixin.unLockRecord(id)
       */
      unLockRecord: "unLockRecord",

      /**
       * set Draft Record
       * Overwrite method recordDetailActionMixin.setDraftRecord(id)
       */
      setDraftRecord: "setDraftRecord",

      /**
       * Check Out File
       * Overwrite recordDetailActionMixin.checkOutFile(id)
       */
      checkOutFile: "checkOutFile",

      /**
       * Check Out the File as a PDF document, so that e-signature can be applied locally at the client station
       * Overwrite recordDetailActionMixin.checkOutFileForSign(id)
       */
      checkOutFileForSign: "checkOutFileForSign",

      /**
       * Release File
       * Overwrite recordDetailActionMixin.releaseFile(id)
       */
      releaseFile: "releaseFile",

      /**
       * Check In File
       * Overwrite recordDetailActionMixin.checkInFile(payload)
       */
      checkInFile: "checkInFile",

      /**
       * uploadFile
       */
      uploadFile: "uploadFile",

      /**
       * upload Compound Document File
       * Overwrite fileCheckInMixin.uploadCompoundDocumentFile(payload)
       * @param {{id: Number, file: any, insertBeforePage: Number, enqueue: Boolean, comments: string}} payload
       * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
       */
      uploadCompoundDocumentFile: "uploadCompoundDocumentFile",

      /**
       * create Record
       * Overwrite recordDetailActionMixin.createRecord(payload)
       */
      createRecord: "createRecord",

      /**
       * create Agenda Meeting
       * remarks: Overwrite recordDetailActionMixin.createAgendaMeeting(payload)
       * @param payload
       */
      createAgendaMeeting: "createAgendaMeeting",

      /**
       * create Agenda Item
       * remarks: Overwrite recordDetailActionMixin.createAgendaItem(payload)
       * @param payload
       */
      createAgendaItem: "createAgendaItem",

      /**
       * save Record
       * Overwrite recordDetailActionMixin.saveRecord(payload)
       */
      saveRecord: "saveRecord",

      /**
       * delete Record
       * Overwrite recordDetailActionMixin.deleteRecord(payload)
       */
      deleteRecord: "deleteRecord",

      /**
       * set New Record Category
       * Overwrite recordDetailActionMixin.setNewRecordCategory(id)
       */
      setNewRecordCategory: "setNewRecordCategory",

      /**
       * set current Record Text Field Value
       * Overwrite recordDetailActionMixin.setRecordTextFieldValue(id)
       */
      setRecordTextFieldValue: "setRecordTextFieldValue",

      /**
       * set Database Lookup Data
       * Overwrite recordDetailActionMixin.setDatabaseLookupData(id)
       */
      setDatabaseLookupData: "setDatabaseLookupData",

      /**
       * Overwrite Abstract method: recordDetailActionMixin.checkInScannedFile()
       * which check In Scanned File
       * @param {{insert: boolean, insertAtBeginning: boolean}|undefined} payload
       */
      checkInScannedFile: "checkInScannedFile",

      /**
       * Overwrite Abstract method: getRecordInfo
       * @param {id:number} id
       * @return record
       */
      getRecordInfo: "getRecordInfo",

      /**
       * Overwrite Abstract method: recordDetailActionMixin.heckInEditedDocument()
       * which check-in an edited record file version
       * @param {{id:number, version:number, extension:string, comment:string, ocr:boolean, draft:boolean }} payload
       * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
       */
      checkInEditedDocument: "checkInEditedDocument",

      /**
       * Overwrite Abstract method: setFileVersions
       * @param {id:number} id
       * @return record
       */
      setFileVersions: "setFileVersions",

      /**
       * post Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.postAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      postAgendaMeeting: "postAgendaMeeting",

      /**
       * un post Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.unPostAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      unPostAgendaMeeting: "unPostAgendaMeeting",

      /**
       * publish Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.publishAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      publishAgendaMeeting: "publishAgendaMeeting",

      /**
       * un publish Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.publishAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      unPublishAgendaMeeting: "unPublishAgendaMeeting",

      /**
       * submit Agenda Item
       * Overwrite Abstract method recordDetailActionMixin.submitAgendaItem
       * @param {id:number} id Agenda Item id
       * @return record
       */
      submitAgendaItem: "submitAgendaItem",

      /**
       * recall Agenda Item
       * Overwrite Abstract method recordDetailActionMixin.recallAgendaItem
       * @param {id:number} id Agenda Item id
       * @return record
       */
      recallAgendaItem: "recallAgendaItem",

      /**
       * create Agenda
       */
      createAgenda: "createAgenda",

      /**
       * create Agenda Packet
       */
      createAgendaPacket: "createAgendaPacket",

      /**
       * Overwrite Abstract method: setChildRecords
       */
      setChildRecords: "setChildRecords",

      /**
       * Overwrite Abstract method: referAgendaItem
       */
      referAgendaItem: "referAgendaItem",

      /**
       * Overwrite Abstract method: deferAgendaItem
       */
      deferAgendaItem: "deferAgendaItem",

      /**
       * Overwrite Abstract method: deleteRecordShortcut
       */
      deleteRecordShortcut: "deleteRecordShortcut",

      /**
       * Overwrite Abstract method: reindexDocument
       */
      reindexDocument: "reindexDocument",

      /**
       * Overwrite Abstract method: reindexFile
       */
      reindexFile: "reindexFile",

      /**
       * Overwrite Abstract method: setDocuments
       */
      setDocuments: "setDocuments",

      /**
       * start Workflow Project
       * Overwrite Abstract method: recordDetailActionMixin.startWorkflowProject()
       */
      startWorkflowProject: "startWorkflowProject",

      /**
       * change the template of the new project
       * remarks: Overwrite Abstract method: recordDetailActionMixin.setProjectNewTemplate()
       */
      setProjectNewTemplate: "setProjectNewTemplate",

      /**
       * set New Workflow Project
       * remarks: Overwrite Abstract method: recordDetailActionMixin.setNewWorkflowProject()
       */
      setNewWorkflowProject: "setNewWorkflowProject",

      /**
       * submit Meeting Agenda Items
       */
      submitMeetingAgendaItems: "submitMeetingAgendaItems",

      /**
       *  submit Section Agenda Items
       */
      submitSectionAgendaItems: "submitSectionAgendaItems",

      /**
       * recall Meeting Agenda Items
       */
      recallMeetingAgendaItems: "recallMeetingAgendaItems",

      /**
       *  recall Section Agenda Items
       */
      recallSectionAgendaItems: "recallSectionAgendaItems"
    }),
    ...mapMutations({
      setUploadFileProgress: SET_UPLOAD_FILE_PROGRESS,
      setNewRecord: SET_RECORD_NEW,
      setEditRecord: SET_RECORD_EDIT,
      setRecordMode: SET_RECORD_MODE,
      updateRecordInfo: UPDATE_RECORD_INFO,
      setMovedRecord: SET_MOVED_RECORD,
      setLocalUploadedFile: SET_LOCAL_UPLOADED_FILE,
      appendRecords: APPEND_RECORDS,
      setNewRecordProject: SET_RECORD_NEW_PROJECT
    }),

    /**
     * Overwrite recordDetailActionMixin.closeUploadFileDropdownParentMenu
     */
    closeUploadFileDropdownParentMenu() {
      this.$refs.refDropDownMenuAcquire?.closeMenu();
    },

    /**
     * Overwrite recordDetailActionMixin.closeCheckInFileDropdownParentMenu
     */
    closeCheckInFileDropdownParentMenu() {
      this.$refs.refDropDownMenuActions?.closeMenu();
    }
  }
};
</script>
